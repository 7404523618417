import {CanActivateChildFn,CanActivateFn,CanMatchFn,GuardResult,Router} from '@angular/router';
import {catchError,filter,firstValueFrom,map,of} from 'rxjs';
import {inject} from '@angular/core';
import {USER_ROLE} from '../common/enums/user-role.enum';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthenticationService} from '../authentication/authentication.service';

export const adminGuardActivate:CanActivateFn=():Promise<GuardResult>=>{
	const router=inject(Router);
	return firstValueFrom(inject(AuthenticationService).user.pipe(
		filter((user)=>user!==undefined),
		map((user)=>{
			if(!user){
				return router.parseUrl('/');
			}else{
				if(user.role?.id!==USER_ROLE.Admin){
					return router.parseUrl('/error#FORBIDDEN');
				}else{
					return true;
				}
			}
		}),
		catchError((error:HttpErrorResponse)=>{
			if(error.status===401){
				return of(true);
			}else{
				return of(true);
			}
		})
	));
};

export const adminGuardMatch:CanMatchFn=async():Promise<GuardResult>=>{
	const router=inject(Router);
	return firstValueFrom(inject(AuthenticationService).user.pipe(
		filter((user)=>user!==undefined),
		map((user)=>{
			if(!user){
				return router.parseUrl('/');
			}else{
				if(user.role?.id!==USER_ROLE.Admin){
					return router.parseUrl('/error#FORBIDDEN');
				}else{
					return true;
				}
			}
		}),
		catchError((error:HttpErrorResponse)=>{
			if(error.status===401){
				return of(true);
			}else{
				return of(true);
			}
		})
	));
};

export const adminGuardActivateChild:CanActivateChildFn=async():Promise<GuardResult>=>{
	const router=inject(Router);
	return firstValueFrom(inject(AuthenticationService).user.pipe(
		filter((user)=>user!==undefined),
		map((user)=>{
			if(!user){
				return router.parseUrl('/');
			}else{
				if(user.role?.id!==USER_ROLE.Admin){
					return router.parseUrl('/error#FORBIDDEN');
				}else{
					return true;
				}
			}
		}),
		catchError((error:HttpErrorResponse)=>{
			if(error.status===401){
				return of(true);
			}else{
				return of(true);
			}
		})
	));
};

